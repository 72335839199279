import React from "react";

export default function GitHubIcon(props: React.ComponentProps<"svg">) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.761963 9.90476C0.761963 4.85562 4.85644 0.761902 9.90482 0.761902C14.954 0.761902 19.0477 4.85562 19.0477 9.90476C19.0477 13.9429 16.4313 17.3691 12.8008 18.5798C12.3368 18.6697 12.1905 18.384 12.1905 18.141V15.632C12.1905 14.7787 11.8911 14.2225 11.5635 13.939C13.5993 13.7128 15.738 12.9394 15.738 9.42781C15.738 8.42971 15.3837 7.61371 14.797 6.97371C14.8915 6.74362 15.2046 5.81333 14.7071 4.5539C14.7071 4.5539 13.9406 4.30857 12.1951 5.49104C11.4644 5.28838 10.682 5.18705 9.90482 5.18324C9.12768 5.18705 8.34596 5.28838 7.61682 5.49104C5.86977 4.30857 5.10177 4.5539 5.10177 4.5539C4.60501 5.81257 4.91815 6.74285 5.01263 6.97371C4.42825 7.61371 4.07091 8.42895 4.07091 9.42781C4.07091 12.9303 6.20577 13.7143 8.23625 13.9467C7.97415 14.1745 7.69225 14.8375 7.65568 15.1688C7.61911 15.5 7.61911 16.158 7.61911 16.4389V18.141C7.61911 18.3817 7.4713 18.6651 7.01491 18.5806C3.38139 17.3714 0.761963 13.9444 0.761963 9.90476Z"
        stroke="#007F6D"
      />
    </svg>
  );
}
